<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="项目名称" :initialContent="detail.name" :disabled="true"></inputItem>
          <inputItem title="业务归属" :initialContent="detail.owner" :disabled="true"></inputItem>
          <inputItem title="客户名称" v-model="detail.customer_name" :disabled="disabled"></inputItem>
          <inputItem title="纳税人识别号" v-model="detail.invoice_identify" :disabled="disabled"></inputItem>
          <inputItem title="地址" v-model="detail.invoice_address" :disabled="disabled"></inputItem>
          <inputItem title="电话" v-model="detail.invoice_tel" :disabled="disabled"></inputItem>
          <inputItem title="开户银行" v-model="detail.invoice_bank_name" :disabled="disabled"></inputItem>
          <inputItem title="银行帐号" v-model="detail.invoice_bank_number" :disabled="disabled"></inputItem>
          <inputItem title="预计回款时间" type="date" :initialContent="formatDate(detail.pre_time, 'yyyy-MM-dd')" :disabled="disabled" name="pre_time" :contentChanged="contentChanged"></inputItem>
          <inputItem title="发票种类" v-model="detail.invoice_tariff" :disabled="disabled" type="select" :options="invoiceTariffOption"></inputItem>
          <inputItem title="发票税率" :initialContent="String(detail.invoice_type)" :disabled="disabled" name="invoice_type" type="select" :options="invoiceTypeOption" :contentChanged="contentChanged"></inputItem>
          <inputItem title="开票金额" :initialContent="formatMoney(detail.invoice_price)" :disabled="true"></inputItem>
          <inputItem v-if="detail.add_time" title="申请时间" :initialContent="formatDate(detail.add_time, 'yyyy-MM-dd')" :disabled="true"></inputItem>
          <inputItem title="申请备注" v-model="detail.remark" :disabled="disabled"></inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="开票清单" label="开票清单">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">开票清单{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)" v-if="!disabled" />
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <div class="input-left"> {{ item.goods_name }} </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 税务分类 </div>
                  <div class="input-left"> {{ item.invoice_category_tax }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 开票数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" @input="changeNum(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 规格型号 </div>
                  <div class="input-left"> {{ item.goods_model }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 开票单价 </div>
                  <div class="input-left text-right margin-lr10"> {{ item.price }} </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 商品单位 </div>
                  <div class="input-left"> {{ item.goods_unit }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 开票金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" @input="changeTotal(item)" />
                </div>
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetProjectInvoice, SaveProjectInvoice, AuditProjectInvoice, CancelProjectInvoice } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
      disabled: false,
      invoiceTariffOption: [{ text: "专票", value: "专票" }, { text: "普票", value: "普票" }],
      invoiceTypeOption: [{ text: "3", value: "3" }, { text: "6", value: "6" }, { text: "9", value: "9" }, { text: "13", value: "13" }]
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    deleteItem: function (index) {
      this.items.splice(index, 1);
      this.setTotal();
    },
    changeNum: function (item) {
      item.total = item.price * item.num;
      this.setTotal();
    },
    changeTotal: function (item) {
      item.num = item.total / item.price;
      this.setTotal();
    },
    setTotal: function () {
      var total = 0;
      this.items.forEach(function (item) {
        total += Number(item.total);
      });
      this.detail.invoice_price = total;
    },
    contentChanged: function (index, value) {
      switch (index) {
        case "pre_time":
          this.detail.pre_time = value;
          break;
        case "invoice_type":
          this.detail.invoice_type = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveProject(1);
          break;
        case "提交审核":
          this.saveProject(2);
          break;
        case "审核通过":
          this.auditProject(true, "同意");
          break;
        case "审核拒绝":
          this.auditProject(false, "");
          break;
        case "撤回":
          this.cancelProject();
          break;
      }
    },
    saveProject: function (state) {
      this.detail.state = state;
      submitAction(this, SaveProjectInvoice, { model: this.detail, items: this.items }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditProject: function (pass, memo) {
      auditAction(this, AuditProjectInvoice, this.id, pass, memo);
    },
    cancelProject: function () {
      cancelAction(this, CancelProjectInvoice, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetProjectInvoice, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("提交审核") < 0;
        this.setTotal();
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>